var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(auth) {
        this.auth = auth;
    }
    TokenInterceptor.prototype.intercept = function (req, next) {
        /* const Token = localStorage.getItem("bearerToken");
        if (bearerToken) {
          request = request.clone({
            headers: request.headers.set("Authorization", `Bearer ${bearerToken}`)
          });
        }
        return next.handle(request); */
        if (this.auth && this.auth.user && this.auth.user.bearerToken) {
            // Get the auth token from the service.
            var authToken = this.auth.user.bearerToken;
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            var authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authToken)
            });
            // send cloned request with header to the next handler.
            return next.handle(authReq);
        }
        else
            return next.handle(req);
    };
    return TokenInterceptor;
}());
export { TokenInterceptor };
