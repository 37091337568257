import { environment as env } from '@env/environment';
import { ConfigResult, OidcConfigService, OidcSecurityService, OpenIdConfiguration } from 'angular-auth-oidc-client';
import 'hammerjs';
export function loadConfig(oidcConfigService) {
    return function () { return oidcConfigService.load_using_stsServer(env.stsUrl); };
}
var AppModule = /** @class */ (function () {
    function AppModule(oidcSecurityService, oidcConfigService) {
        var _this = this;
        this.oidcSecurityService = oidcSecurityService;
        this.oidcConfigService = oidcConfigService;
        this.oidcConfigService.onConfigurationLoaded.subscribe(function (configResult) {
            var config = {
                stsServer: configResult.customConfig.stsServer,
                redirect_url: "" + env.clientUrl + env.redirectRoute,
                post_login_route: env.redirectRoute,
                post_logout_redirect_uri: env.clientUrl,
                client_id: env.clientId,
                scope: 'openid profile email efapi:access:full',
                response_type: 'code',
                silent_renew: true,
                silent_renew_url: env.clientUrl + "/" + env.silentRenewRoute,
                log_console_debug_active: !!env.logConsoleDebugActive,
                disable_iat_offset_validation: true,
                auto_userinfo: true,
            };
            _this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
        });
    }
    return AppModule;
}());
export { AppModule };
