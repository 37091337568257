import { User } from 'app/core/models/user';
import { ALNIdentityAuthUser } from './aln-identity-user';
import { environment as env } from '../../../environments/environment';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "angular-auth-oidc-client";
var ALNIdentityAuthService = /** @class */ (function () {
    function ALNIdentityAuthService(oidcSecurityService, _tokenHelperService) {
        var _this = this;
        this.oidcSecurityService = oidcSecurityService;
        this._tokenHelperService = _tokenHelperService;
        this.authorizedSource = new Subject();
        this._isAuthorized = false;
        this._user = new User();
        this.authorized$ = this.authorizedSource.asObservable();
        this.oidcSecurityService.getIsAuthorized().subscribe(function (isAuthorized) {
            _this._isAuthorized = isAuthorized;
            if (isAuthorized) {
                var token = _this.oidcSecurityService.getToken();
                console.log(token);
                var userData = _this._tokenHelperService.getPayloadFromToken(token, false);
                _this.mapUser(userData);
            }
            else {
                _this._user = new User();
            }
            _this.authorizedSource.next(isAuthorized);
        });
        if (this.oidcSecurityService.moduleSetup) {
            this.doCallbackLogicIfRequired();
        }
        else {
            this.oidcSecurityService.onModuleSetup.subscribe(function () {
                _this.doCallbackLogicIfRequired();
            });
        }
    }
    Object.defineProperty(ALNIdentityAuthService.prototype, "isAuthorized", {
        get: function () {
            return this._isAuthorized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ALNIdentityAuthService.prototype, "user", {
        get: function () {
            if (!this.isAuthorized) {
                this._user = new User();
            }
            return this._user;
        },
        enumerable: true,
        configurable: true
    });
    ALNIdentityAuthService.prototype.login = function () {
        this.oidcSecurityService.authorize();
    };
    ALNIdentityAuthService.prototype.logout = function () {
        this.oidcSecurityService.logoff();
    };
    ALNIdentityAuthService.prototype.doCallbackLogicIfRequired = function () {
        this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
    };
    ALNIdentityAuthService.prototype.mapUser = function (userData) {
        var parseObj = function (values) { return Array.isArray(values)
            ? values.map(function (v) { return JSON.parse(v); })
            : [JSON.parse(values)]; };
        var userInfo = new ALNIdentityAuthUser(userData);
        this._user.userId = userInfo.userId;
        this._user.userName = userInfo.preferred_username;
        this._user.fullName = userInfo.name;
        this._user.profileImageURL = userInfo.profilePictureUrl.startsWith("https://")
            ? userInfo.profilePictureUrl
            : "" + env.stsUrl + userInfo.profilePictureUrl;
        this._user.bearerToken = this.oidcSecurityService.getToken();
        this._user.organisation = userInfo.organizationName;
        this._user.applicationRoles = Array.isArray(userInfo.userRoles)
            ? userInfo.userRoles
            : [userInfo.userRoles];
        this._user.clients = parseObj(userInfo.products);
        this._user.allClients = parseObj(userInfo.allProducts);
        localStorage.setItem('currentUser', JSON.stringify(this._user));
    };
    ALNIdentityAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ALNIdentityAuthService_Factory() { return new ALNIdentityAuthService(i0.ɵɵinject(i1.OidcSecurityService), i0.ɵɵinject(i1.TokenHelperService)); }, token: ALNIdentityAuthService, providedIn: "root" });
    return ALNIdentityAuthService;
}());
export { ALNIdentityAuthService };
