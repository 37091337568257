import { map } from 'rxjs/operators';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, oidcSecurityService, authService) {
        this.router = router;
        this.oidcSecurityService = oidcSecurityService;
        this.authService = authService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        return this.checkUser();
    };
    AuthGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    AuthGuard.prototype.canLoad = function (state) {
        return this.checkUser();
    };
    AuthGuard.prototype.checkUser = function () {
        // if (!this.authService.isAuthorized) {
        //     this.oidcSecurityService.authorize();
        // }
        var _this = this;
        return this.oidcSecurityService.getIsAuthorized().pipe(map(function (isAuthorized) {
            if (!isAuthorized) {
                _this.oidcSecurityService.authorize();
                return false;
            }
            return true;
        }));
    };
    return AuthGuard;
}());
export { AuthGuard };
