import { env } from 'process';
import { securityAdminRole } from '../auth.constants';
var SubscriptionModalComponent = /** @class */ (function () {
    function SubscriptionModalComponent(_alnIdentityAuthService) {
        this._alnIdentityAuthService = _alnIdentityAuthService;
    }
    Object.defineProperty(SubscriptionModalComponent.prototype, "isSecurityAdminRole", {
        get: function () {
            if (!this._alnIdentityAuthService.user) {
                return false;
            }
            var currentClientId = this._alnIdentityAuthService
                .user.allClients.find(function (c) { return c.clientId === env.clientId; }).id;
            var result = this._alnIdentityAuthService.user.applicationRoles
                .includes(securityAdminRole + "_" + currentClientId);
            return result;
        },
        enumerable: true,
        configurable: true
    });
    return SubscriptionModalComponent;
}());
export { SubscriptionModalComponent };
