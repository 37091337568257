import { OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
var AppGatewayComponent = /** @class */ (function () {
    function AppGatewayComponent(route, authenticationService, alnIdentityAuthService, router) {
        this.route = route;
        this.authenticationService = authenticationService;
        this.alnIdentityAuthService = alnIdentityAuthService;
        this.router = router;
    }
    AppGatewayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.token = this.route.snapshot.queryParams.token;
        this.landingPage = this.route.snapshot.params['landingpage'];
        this.orderNumber = this.route.snapshot.queryParams.orderNumber;
        this.caseNumber = this.route.snapshot.queryParams.caseNumber;
        this.courtID = this.route.snapshot.queryParams.courtID;
        this.currentUser = this.authenticationService.currentUserValue;
        this.EFilingCentralAppBaseUri = environment.eFilingCentralAppBaseUri;
        this.alnIdentityAuthService.authorized$.subscribe(function (auth) {
            if (auth === false)
                return;
            _this.currentUser = _this.alnIdentityAuthService.user;
            _this.landingPage = _this.route.snapshot.params['landingpage'];
            if (!_this.authenticationService.eFilingSettings)
                _this.authenticationService.getSettingsFrmAPI().subscribe(function () {
                    _this.redirect();
                });
            else
                _this.redirect();
        });
    };
    AppGatewayComponent.prototype.redirect = function () {
        var dashboard = this.route.snapshot.queryParams.dashboard;
        var orderNumber = this.route.snapshot.queryParams.orderNumber;
        var caseNumber = this.route.snapshot.queryParams.caseNumber;
        if (this.landingPage === 'home') {
            this.router.navigate['/dashboard'];
        }
        else if (this.landingPage === 'mail') {
            window.location.href = environment.centralAppBaseUri + "/apps/appgateway/" + this.currentUser.userId + "/mail";
        }
        else if (this.landingPage === 'calendar') {
            window.location.href = environment.centralAppBaseUri + "/apps/appgateway/" + this.currentUser.userId + "/calendar";
        }
        else if (this.landingPage === 'new-filing' || this.landingPage === 'subsequent') {
            var efilingRedirectURL = this.authenticationService.eFilingSettings.find(function (x) { return x.key == 'EFilingOCAppBaseUri'; }).defaultValue;
            var landing = this.landingPage.includes('subsequent') ? '/subsequent' : '/new-filing';
            if (dashboard == "OC") {
                if (caseNumber) {
                    window.location.href = efilingRedirectURL + '/appgateway' + landing + "?caseNumber=" + caseNumber + "&token=" + this.currentUser.bearerToken;
                }
                else {
                    window.location.href = efilingRedirectURL + '/appgateway' + landing + "?orderNumber=" + orderNumber + "&token=" + this.currentUser.bearerToken;
                }
            }
            else //LA 
             {
                if (caseNumber) {
                    this.router.navigate([this.landingPage.includes('subsequent') ? "/subsequent/filing/case/" + caseNumber : "/new-filing/" + orderNumber], { relativeTo: this.route });
                }
                else {
                    this.router.navigate([this.landingPage.includes('subsequent') ? "/subsequent/filing/" + orderNumber : "/new-filing/" + orderNumber], { relativeTo: this.route });
                }
            }
        }
        else {
            window.location.href = environment.eFilingCentralAppBaseUri + "/" + this.landingPage;
        }
    };
    AppGatewayComponent.prototype.getCaseRedirectUrl = function () {
        if (this.orderNumber)
            return this.landingPage.includes('subsequent') ? "/subsequent/filing/" + this.orderNumber : "/new-filing/" + this.orderNumber;
        else if (this.caseNumber)
            return this.landingPage.includes('subsequent') ? "/subsequent/filing/case/" + this.caseNumber : "/new-filing/" + this.caseNumber;
        else if (this.courtID)
            return this.landingPage.includes('subsequent') ? "/subsequent/filing/courtID/" + this.courtID : "/new-filing/courtID/" + this.courtID;
        else
            return this.landingPage.includes('subsequent') ? '/subsequent/filing' : '/new-filing';
    };
    return AppGatewayComponent;
}());
export { AppGatewayComponent };
